import {
  createUnifiedTheme,
  genPageTheme,
  themes,
  shapes,
  UnifiedThemeOptions,
} from '@backstage/theme';

const theme = themes.light.getTheme('v4') as UnifiedThemeOptions;

export const atgDigitalTheme = createUnifiedTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: '#5A00FF',
    },
    secondary: {
      main: '#565a6e',
    },
    errorBackground: '#5A00FF',
    warningBackground: '#8f5e15',
    infoBackground: '#180f5e',
    navigation: {
      background: '#180f5e',
      indicator: '#8f5e15',
      color: '#d5d6db',
      selectedColor: '#ffffff',
    },
  } as any,
  defaultPageTheme: 'home',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#5A00FF', '#180f5e'], shape: shapes.wave }),
    other: genPageTheme({ colors: ['#5A00FF', '#180f5e'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#5A00FF', '#180f5e'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#5A00FF', '#180f5e'], shape: shapes.wave }),
  },
})
