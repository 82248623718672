import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';
import { TechRadarClient } from './lib/TechRadarClient';
import { catalogImportApiRef } from '@backstage/plugin-catalog-import';
import { ImportOverride } from './components/catalog/ImportOverride';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: catalogImportApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      scmAuthApi: scmAuthApiRef,
      identityApi: identityApiRef,
      scmIntegrationsApi: scmIntegrationsApiRef,
      catalogApi: catalogApiRef,
      configApi: configApiRef,
    },
    factory: ({
      discoveryApi,
      scmAuthApi,
      identityApi,
      scmIntegrationsApi,
      catalogApi,
      configApi,
    }) =>
      new ImportOverride({
        discoveryApi,
        scmAuthApi,
        scmIntegrationsApi,
        identityApi,
        catalogApi,
        configApi,
      }),
  }),
  createApiFactory(techRadarApiRef, new TechRadarClient()),
  ScmAuth.createDefaultApiFactory(),
];
