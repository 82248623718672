import React from 'react';
import { makeStyles } from '@material-ui/core';
import atgLogo from '../../assets/img/atg-neue-white.png';

const useStyles = makeStyles({
  path: {
    width: 'auto',
    height: 30,
    fill: '#7df3e1',
  },
});

const LogoFull = () => {
  const classes = useStyles();

  return <img src={atgLogo} alt="logo" className={classes.path} />;
};

export default LogoFull;
