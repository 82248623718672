/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  RadarEntry,
  RadarQuadrant,
  RadarRing,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';

const rings = new Array<RadarRing>();
rings.push({ id: 'adopt', name: 'ADOPT', color: '#5BA300' });
rings.push({ id: 'trial', name: 'TRIAL', color: '#009EB0' });
rings.push({ id: 'assess', name: 'ASSESS', color: '#C7BA00' });
rings.push({ id: 'hold', name: 'HOLD', color: '#E09B96' });

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'techniques', name: 'Techniques' });
quadrants.push({ id: 'tools', name: 'Tools' });
quadrants.push({ id: 'languages-frameworks', name: 'Languages & Frameworks' });
quadrants.push({ id: 'platforms', name: 'Platforms' });

const entries = new Array<RadarEntry>();

// Languages & Frameworks
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2023-05-16'),
      description: 'Migrated from Miro',
    },
  ],
  key: 'react',
  id: 'react',
  title: 'React',
  quadrant: 'languages-frameworks',
  links: [
    {
      url: 'https://react.dev/',
      title: 'React',
    },
  ],
  description:
    'React lets you build user interfaces out of individual pieces called components. Create your own React components like Thumbnail, LikeButton, and Video. Then combine them into entire screens, pages, and apps.',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2023-05-16'),
      description: 'Migrated from Miro',
    },
  ],
  key: 'typescript',
  id: 'typescript',
  title: 'TypeScript',
  quadrant: 'languages-frameworks',
  links: [
    {
      url: 'https://www.typescriptlang.org/',
      title: 'TypeScript',
    },
  ],
  description: 'TypeScript is JavaScript with syntax for types.',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2023-05-16'),
      description: 'Migrated from Miro',
    },
  ],
  links: [
    {
      url: 'https://www.apollographql.com/docs/apollo-server/',
      title: 'Introduction to Apollo Server',
    },
  ],
  key: 'apollo-server',
  id: 'apollo-server',
  title: 'Apollo Server',
  quadrant: 'languages-frameworks',
  description:
    "Apollo Server is an open-source, spec-compliant GraphQL server that's compatible with any GraphQL client, including Apollo Client.",
});

// Tools
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2023-05-16'),
      description: 'Migrated from Miro',
    },
  ],
  links: [
    {
      url: 'https://github.com',
      title: 'Learn more',
    },
  ],
  key: 'github-actions',
  id: 'github-actions',
  title: 'GitHub Actions',
  quadrant: 'tools',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2023-05-16'),
      description: 'Migrated from Miro',
    },
  ],
  links: [
    {
      url: 'https://miro.com/',
      title: 'Miro',
    },
  ],
  key: 'miro',
  id: 'miro',
  title: 'Miro',
  quadrant: 'tools',
  description: 'The leading visual collaboration platform',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2023-05-16'),
      description: 'Deployed our new ATG Backstage',
    },
  ],
  links: [
    {
      url: 'https://backstage.io/',
      title: 'Backstage Docs',
    },
  ],
  key: 'backstage',
  id: 'backstage',
  title: 'Backstage',
  quadrant: 'tools',
  description: 'An open platform for building developer portals',
});

// Techniques
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2023-05-16'),
      description: 'Migrated from Miro',
    },
  ],
  key: 'multi-region-deployments',
  id: 'multi-region-deployments',
  title: 'Multi Region Deployments',
  quadrant: 'techniques',
  description: 'Deploy applications which are specific to regions and brands.',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2023-05-16'),
      description: 'Migrated from Miro',
    },
  ],
  links: [
    {
      url: 'https://aws.amazon.com/microservices/',
      title: 'AWS: What are Microservices',
    },
  ],
  key: 'microservices',
  id: 'microservices',
  title: 'Microservices',
  quadrant: 'techniques',
  description:
    'Microservices are an architectural and organizational approach to software development where software is composed of small independent services that communicate over well-defined APIs. These services are owned by small, self-contained teams.',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2023-05-16'),
      description: 'Migrated from Miro',
    },
  ],
  links: [
    {
      url: 'https://bradfrost.com/blog/post/atomic-web-design/',
      title: 'Blog: Atomic Design',
    },
    {
      url: 'https://atomicdesign.bradfrost.com/',
      title: 'Book: Atomic Design',
    },
  ],
  key: 'atomic-design',
  id: 'atomic-design',
  title: 'Atomic Design',
  quadrant: 'techniques',
  description: 'Atomic design is methodology for creating design systems.',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2023-05-16'),
      description: 'Migrated from Miro',
    },
  ],
  links: [
    {
      url: 'https://martinfowler.com/articles/micro-frontends.html',
      title: 'Martin Fowler: Micro Frontends',
    },
  ],
  key: 'micro-frontends',
  id: 'micro-frontends',
  title: 'Micro Frontends',
  quadrant: 'techniques',
  description:
    'Micro frontends are all about slicing up big and scary things into smaller, more manageable pieces, and then being explicit about the dependencies between them.',
});

// Platforms
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2023-05-16'),
      description: 'Migrated from Miro',
    },
  ],
  links: [
    {
      url: 'https://aws.amazon.com/eventbridge/',
      title: 'Amazon EventBridge',
    },
  ],
  key: 'aws-event-bridge',
  id: 'aws-event-bridge',
  title: 'AWS Event Bridge',
  quadrant: 'platforms',
  description:
    'Build event-driven applications at scale across AWS, existing systems, or SaaS applications.',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2023-05-16'),
      description: 'Migrated from Miro',
    },
  ],
  links: [
    {
      url: 'https://aws.amazon.com/dynamodb/',
      title: 'Amazon DynamoDB',
    },
  ],
  key: 'aws-dynamodb',
  id: 'aws-dynamodb',
  title: 'Amazon DynamoDB',
  quadrant: 'platforms',
  description:
    'Fast, flexible NoSQL database service for single-digit millisecond performance at any scale',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2023-05-16'),
      description: 'Migrated from Miro',
    },
  ],
  links: [
    {
      url: 'https://umbraco.com/products/umbraco-heartcore/',
      title: 'Umbraco Heartcore',
    },
  ],
  key: 'umbraco-heartcore',
  id: 'umbraco-heartcore',
  title: 'Umbraco Heartcore',
  quadrant: 'platforms',
  description: "A headless CMS that's heartful and hardcore.",
});

export const techRadarData: TechRadarLoaderResponse = {
  entries,
  quadrants,
  rings,
};
