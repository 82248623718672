import React from 'react';
import { Content, Header, Page } from '@backstage/core-components';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { Grid, makeStyles } from '@material-ui/core';
import atgLogo from '../../assets/img/atg-neue.png';

const useStyles = makeStyles(theme => ({
  searchBar: {
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '10px',
    borderRadius: '50px',
    margin: 'auto',
    borderStyle: 'none',
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  container: {
    margin: theme.spacing(5, 0),
  },
  path: {
    width: 'auto',
    height: 100,
    fill: '#7df3e1',
  },
}));

export const HomePage = () => {
  const { path, container, searchBar, searchBarOutline } = useStyles();

  return (
    <>
      <Header title="ATG Backstage" pageTitleOverride="Home" />
      <SearchContextProvider>
        <Page themeId="home">
          <Content>
            <Grid container justifyContent="center" spacing={6}>
              <div className={container}>
                <img src={atgLogo} alt="logo" className={path} />
              </div>
              <Grid container item xs={12} alignItems="center" direction="row">
                <HomePageSearchBar
                  InputProps={{
                    classes: {
                      notchedOutline: searchBarOutline,
                      root: searchBar,
                    },
                  }}
                  placeholder="Search"
                />
              </Grid>
            </Grid>
          </Content>
        </Page>
      </SearchContextProvider>
    </>
  );
};
