import { CatalogImportClient } from '@backstage/plugin-catalog-import';

export class ImportOverride extends CatalogImportClient {
  private readonly importConfigApi;
  constructor(options: any) {
    super(options);
    this.importConfigApi = options.configApi;
  }
  preparePullRequest(): Promise<{ title: string; body: string }> {
    const appTitle = this.importConfigApi.getString('app.title');
    const appBaseUrl = this.importConfigApi.getString('app.baseUrl');
    return Promise.resolve({
      title: 'docs(backstage): Add catalog-info.yaml config file',
      body: `
This pull request adds a **Backstage entity metadata file** to this repository so that the component can be added to the [${appTitle} software catalog](${appBaseUrl}).

After this pull request is merged, the component will become available.

We would recommend that you add the following annotations to the file:

- \`github.com/project-slug\`: \`atg-digital/<repo-slug>\`
- \`github.com/team-slug\`: \`dir:.\` (if you are adding TechDocs to the repository)

Useful spec values are:

- \`spec.type\`: \`service\`
- \`spec.lifecycle\`: \`production\` (or \`experimental\` if you are not sure)
- \`spec.owner\`: \`team-services\`
- \`spec.system\`: \`catalogue\`

For more information, read an [overview of the Backstage software catalog](https://backstage.io/docs/features/software-catalog/).`,
    });
  }
}
